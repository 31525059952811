<script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  //import Swal from "sweetalert2";

  export default {
    props: {
        formType:{
            required: true,
            type: String,
        }
    },
    data() {
      return {
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          enableTime: true,
        },
        productids: [],
        clients:[],
        rfq_statuses:[],
        users:[],
        products:[],
        formData: {
            client_id:'',
            rfq_status:'',

            notes:'',
            issue_date:'',
            due_date:'',
            quotation_date:'',
            reply_date:'',

            assigned_users:[],
            client_ref_number:'',
            sp_ref_number:'',
            products_list:[
                {
                    product_id:'',
                    quantity:0,
                    uom:'',
                    description:'',
                    delivery_date:''
                }
            ]
        }
      };
    },
    components: {
      Multiselect,
      flatPickr,
    },
    methods: {
        addRFQ() {
            if(this.formType == 'add'){
                return this.$store.dispatch("rfqs/addRFQ", this.formData)
                    .then(() => {
                        this.$router.push('/request-for-quotes')
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
              console.log('', this.formData.assigned_users)
            //   return  this.$store.dispatch("rfqs/editRFQ", this.formData)
            //         .then(() => {
            //             this.$router.push('/request-for-quotes')
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            }
        },
        minusamount(key) {
         if(this.formData.products_list[key].quantity >= 2) {
                this.formData.products_list[key].quantity -= 1
            }
        },
        plusamount(key) {
            this.formData.products_list[key].quantity  += 1 
        },
        removeProduct(key){
            this.formData.products_list.splice(key,1)
        },
        addProduct() {
            this.formData.products_list.push({
                    product_id:'',
                    quantity:'',
                    uom:'',
                    description:'',
                    delivery_date:''
                })
        }

    },
    beforeMount() {
        //check if rfq data is available 
        //populate the form with rfq data
        if(this.formType === 'update') {
            this.$store.dispatch('rfqs/getSingleRFQ',this.$route.params.id)
                .then(res => {
                   //get assigned users ID to be populated in the form
                   let newAssignedUsers = []
                   if(res.assigned_users.length) {
                      newAssignedUsers = res.assigned_users.map( user => user.id)
                   }
                    this.formData = {
                      ...res,
                      products_list: res.details,
                      assigned_users: newAssignedUsers
                    }
                    console.log('Form Data', this.formData.assigned_users)
                })
        }
        this.$store.dispatch('rfqs/getRFQStatusList')
        .then(res => this.rfq_statuses = res.map( note => {
            return {
                label: note.name,
                value: note.id
            }
        }))
      this.$store.dispatch('products/getProductList')
        .then(res => this.products = res.map( product => {
            return {
                label: product.name,
                value: product.id
            }
        }))
       this.$store.dispatch('clients/getClientList')
        .then(res => this.clients = res.map( client => {
            return {
                label: client.name,
                value: client.id
            }
        }))

        this.$store.dispatch('users/getUserList')
        .then(res => this.users = res.map( user => {
            return {
                label: user.firstname + user.lastname,
                value: user.id
            }
        })) 
    },
  };
</script>

<template>
    <div class="row justify-content-center">
      <div class="col-xxl-9">
        <div class="card">
          <form class="needs-validation" @submit.prevent="addRFQ">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <div>
                    <!-- <label for="billingName" class="text-muted text-uppercase fw-semibold">Client Info</label> -->
                  </div>
                  <div class="mb-3">
                    <label for="client">Client</label>
                    <div class="input-light">
                        <Multiselect placeholder="Select client" class="form-control w-md" required id="client" v-model="formData.client_id"
                        :close-on-select="true" :searchable="true" :create-option="true" :options="clients" />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="rfq-status">RFQ Status</label>
                    <div class="input-light">
                        <Multiselect 
                        placeholder="Select RFQ status" required class="form-control w-md" id="rfq-status" v-model="formData.rfq_status"
                        :close-on-select="true" :searchable="true" :create-option="true" :options="rfq_statuses" />
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-sm-6 ms-auto">
                  <div class="row">
                    <div class="col-lg-8">
                      <div>
                        <label for="shippingName" class="text-muted text-uppercase fw-semibold"></label>
                      </div>
                    <div class="mb-3">
                        <div>
                            <label for="issue-date">Issue Date</label>
                            <flat-pickr required v-model="formData.issue_date" id="issue-date" placeholder="Select issue date" :config="config"
                            class="form-control bg-light border-light border-0"></flat-pickr>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div>
                            <label for="due-date">Due Date</label>
                            <flat-pickr required v-model="formData.due_date" id="due-date" placeholder="Select due date" :config="config"
                            class="form-control bg-light border-light border-0"></flat-pickr>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div>
                            <label for="reply-date">Reply Date</label>
                            <flat-pickr required v-model="formData.reply_date" id="reply-date" placeholder="Select reply date" :config="config"
                            class="form-control bg-light border-light border-0"></flat-pickr>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div>
                            <label for="quotation_date-date">Quotation Date</label>
                            <flat-pickr required v-model="formData.quotation_date" id="quotation_date-date" placeholder="Select quotation date" :config="config"
                            class="form-control bg-light border-light border-0"></flat-pickr>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="card-body p-4 border-top border-top-dashed">
              <div class="row g-3">
                <div class="col-lg-4 col-sm-6">
                  <label for="client-ref">Client Reference Number</label>
                  <input type="number" required min="1" v-model="formData.client_ref_number" class="form-control bg-light border-0" id="client-ref" placeholder="Client Ref NO." />
                </div>
                <!--end col-->
                <div class="col-lg-4 col-sm-6">
                  <label for="sp-ref">SPay Reference Number</label>
                  <input type="number" required min="1" v-model="formData.sp_ref_number" class="form-control bg-light border-0" id="sp-ref" placeholder="SPay Ref NO." />
                </div>
                <!--end col-->
                <div class="col-lg-4 col-sm-6">
                 <label for="users">Assigned users</label>
                    <div class="input-light">
                        <Multiselect 
                          required 
                          class="form-control w-md"  
                          mode="multiple" 
                          id="users" 
                          v-model="formData.assigned_users"
                          placeholder="Select users"
                          :close-on-select="true" 
                          :searchable="true" 
                          :create-option="true" 
                          :options="users" />
                    </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <div class="card-body p-4 border-top border-top-dashed">
              <div class="table-responsive">
                <table class="invoice-table table table-borderless table-nowrap mb-0">
                  <thead class="align-middle">
                    <tr class="table-active">
                      <th scope="col" style="width: 30px">#</th>
                      <th scope="col">Product Details</th>
                      <th scope="col" style="width: 120px">Quantity</th>
                      <th scope="col" style="width: 100px">UOM</th>
                      <th scope="col">Description</th>
                      <th scope="col" class="text-end" style="width: 150px">Delivery Date</th>
                    </tr>
                  </thead>
                  <tbody id="newlink">

                    <tr v-for="(product,n) in formData.products_list" :key="n" :id="n" class="product">
                      <th scope="row" class="product-id">{{n + 1}}</th>
                      <td class="text-start">
                        <Multiselect class="form-control w-md" 
                         v-model="product.product_id"
                         :close-on-select="true"
                          :searchable="true" :create-option="false" :options="products"
                          @select="selectedv(newproductvalue[n-1])" />
                      </td>
                        <td>
                        <div class="input-step">
                          <button type="button" @click="minusamount(n)">–</button>
                          <input type="number" min="1" v-model="product.quantity" class="product-quantity" 
                          readonly />
                          <button type="button" @click="plusamount(n)">+</button>
                        </div>
                      </td>
                      <td>
                        <input v-model="product.uom" type="text" :id="'productprice'+n" class="form-control product-price bg-light border-0" 
                        placeholder="3" :required="formType =='add'" />
                      </td>
                       <td class="text-end">
                        <div>
                          <input v-model="product.description" type="text" class="form-control bg-light border-0 product-line-price"
                            placeholder="Description" :required="formType =='add'" />
                        </div>
                      </td>
                      <td>
                            <flat-pickr v-model="product.delivery_date" id="quotation_date-date" placeholder="Delivery date" :config="config"
                            class="form-control bg-light border-light border-0"></flat-pickr>
                      </td>
                      <td class="product-removal">
                        <button @click="removeProduct(n)" class="btn btn-success">Delete</button>
                      </td>
                    </tr>

                  </tbody>
                  <tr id="newForm" style="display: none"></tr>
                  <tr>
                    <td colspan="9">
                      <button @click="addProduct" class="btn btn-soft-secondary  mt-1 fw-medium">
                        <i class="ri-add-fill me-1 align-bottom"></i> 
                        Add Item
                      </button>
                    </td>
                  </tr>
                </table>
                <!--end table-->
              </div>
              <!--end row-->
              <div class="mt-4">
                <label for="exampleFormControlTextarea1"
                  class="form-label text-muted text-uppercase fw-semibold">NOTES</label>
                <textarea v-model="formData.notes" class="form-control" id="exampleFormControlTextarea1" placeholder="Notes"
                  rows="2"
                  required></textarea>
              </div>
              <div class="hstack gap-2 justify-content-end d-print-none mt-4">
                <button type="submit" class="btn btn-success">
                  <i class="ri-printer-line align-bottom me-1"></i> 
                  {{ formType  == 'add' ?  'Save' : 'Update'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
</template>
